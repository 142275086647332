import React, { useEffect, useState } from "react";
import { Fade, Zoom } from "react-reveal";
import api from "../../general/api";
import { Helmet } from "react-helmet";

import Header from "../../includes/home/Header";
import styled from "styled-components";
import Footer from "../../includes/home/Footer";
import bg from "../../../assets/images/contact/ContactsBanner.jpg";
import phone_img from "../../../assets/images/contact/Asset 10.svg";
import email_img from "../../../assets/images/contact/Asset 9.svg";
import tab_bg from "../../../assets/images/contact/Banner_Tab.jpg";
import mob_bg from "../../../assets/images/contact/Banner_Mob.jpg";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ButtonLoader from "../../includes/loader/ButtonLoader";

import "sweetalert2/dist/sweetalert2.css";
import { accountsConfig } from "../../../AxiosConfig";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  //sweet alert for success modal
  const showSuccessAlert = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Success",
      showConfirmButton: false,
      iconColor: "#0e2d5e",
    });
  };

  //create enquiry
  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    accountsConfig
      .post("company_profile/create-enquiry-email/", {
        name: name,
        email: email,
        phone: phone,
        message: message,
      })
      .then((response) => {
        const { StatusCode, data } = response.data.app_data;
        if (StatusCode === 6000) {
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
          showSuccessAlert();
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        // setError(true)
      });
  };
  return (
    <>
      <Helmet>
        <title>Contact Us | English Cafe</title>
        <meta
          name="description"
          content="Get in touch with our English Training Academy through our Contacts page. We're here to answer your questions and assist with your language learning journey."
        />
        <meta name="keywords" content="React, Helmet, Metadata" />`
      </Helmet>
      <Container>
        <Banner>
          <Header />
          <Zoom>
            <Text>Contact</Text>
          </Zoom>
        </Banner>
        <Fade bottom>
          <Content>
            <Wrapper>
              <LeftContent>
                <SubTitle>Contact</SubTitle>
                <Cover>
                  <Para>
                    You can reach us
                    <br /> by phone call,whatsapp, email or posting
                  </Para>
                  <Head>Get in touch!</Head>
                </Cover>
                <Cover>
                  <Label>Office Address:</Label>
                  <Addr>
                    The training hub, Opposite APCO Honda,
                    <br /> Mini-bypass road, Puthiyara, <br />
                    Calicut-673004
                  </Addr>
                  <Number>
                    <Icons>
                      <img src={phone_img} alt="phone" />
                    </Icons>
                    <Atag>
                      <a href="tel:+91 7907139138">+91 7907139138</a>
                    </Atag>
                  </Number>
                  <Addr>
                    Office F4-30, Al Shola Building
                    <br /> Port Saeed Street <br />
                    Deira, Dubai
                  </Addr>
                  <Number>
                    <Icons>
                      <img src={phone_img} alt="phone" />
                    </Icons>
                    <Atag>
                    <a href="tel:+97143436131">+971 4 343 6131</a>
                    </Atag>
                  </Number>
                </Cover>
                <Cover>
                  <Label>For General Enquiries:</Label>
                  <Addr>If you have a business inquiry, please contact us</Addr>
                  <Number>
                    <Icons>
                      <img src={email_img} alt="email" />
                    </Icons>
                    <Atag>
                      <a href="mailto:englishcafeofl@gmail.com">
                        englishcafeofl@gmail.com
                      </a>
                    </Atag>
                  </Number>
                </Cover>
              </LeftContent>
              <RightContent>
                <FormCover>
                  <RightHead>
                    Have a question? <br />
                    Please write to us here.
                  </RightHead>
                  <Form onSubmit={(e) => submitHandler(e)}>
                    <Input
                      type="text"
                      placeholder="Name"
                      value={name}
                      required
                      onChange={(e) => setName(e.target.value)}
                    />
                    <Input
                      type="email"
                      placeholder="Email Address"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Input
                      type="number"
                      placeholder="Phone Number"
                      value={phone}
                      required
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <TextArea
                      placeholder="Your Message"
                      value={message}
                      required
                      onChange={(e) => setMessage(e.target.value)}
                    ></TextArea>
                    {isLoading ? (
                      <SendIn>
                        <ButtonLoader />
                      </SendIn>
                    ) : (
                      <CoverSend>
                        <Send type="submit" value="Send" />
                      </CoverSend>
                    )}
                  </Form>
                </FormCover>
              </RightContent>
            </Wrapper>
          </Content>
        </Fade>
        <Iframe>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d7826.166515794976!2d75.79064492433069!3d11.255285567990573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d11.258799699999999!2d75.79528359999999!4m5!1s0x3ba6594459519637%3A0x3bd681c86e3126f1!2senglishcafe!3m2!1d11.2518163!2d75.79774239999999!5e0!3m2!1sen!2sin!4v1676966202817!5m2!1sen!2sin"
            width="100%"
            height="450"
            styles="border:0;"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Iframe>
        <CallBanner>
          <span>Our Call Center :</span>
          <a href="tel:+91 7907139138">+91 7907139138</a>
        </CallBanner>
      </Container>
      <Footer />
    </>
  );
}

export default Contact;

const Container = styled.div``;
const Wrapper = styled.div`
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 1440px) {
    width: 80%;
  }
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`;
const Banner = styled.div`
  background: url(${bg});
  height: 62vh;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 1560px) {
    background-position: center;
  }
  @media all and (max-width: 1280px) {
    background-position: 30%;
  }
  @media all and (max-width: 768px) {
    background: url(${tab_bg});
  }
  @media all and (max-width: 480px) {
    background: url(${mob_bg});
    background-position: center;
    background-size: contain;
  }
`;
const Content = styled.div`
  padding: 40px 0;
`;
const Text = styled.h1`
  color: #fff;
  font-family: mont-medium;
  font-size: 50px;
  margin-left: 350px;
  margin-top: 150px;
  @media all and (max-width: 768px) {
    display: none;
  }
`;
const SubTitle = styled.h4`
  color: var(--blue);
  border-bottom: 1px solid var(--blue);
  padding-bottom: 10px;
  display: inline-block;
  font-family: "mont-medium";
  margin-bottom: 60px;
  @media all and (max-width: 400px) {
    margin-bottom: 10px;
  }
`;
const FormCover = styled.div``;
const CallBanner = styled.div`
  background-color: #f5f4f5;
  padding: 30px 0;
  text-align: center;
  span {
    color: #d92119;
    font-size: 30px;
    @media all and (max-width: 480px) {
      font-size: 18px;
    }
  }
  a {
    margin-left: 10px;
    color: var(--blue);
    font-family: "mont-bold";
    text-decoration: none;
    font-size: 35px;
    @media all and (max-width: 480px) {
      font-size: 20px;
    }
  }
  @media all and (max-width: 480px) {
    padding: 20px 0;
  }
`;

const LeftContent = styled.div`
  width: 60%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const Cover = styled.div`
  margin-bottom: 40px;
`;
const Para = styled.p`
  font-size: 14px;
`;
const Head = styled.h1`
  font-size: 36px;
  font-family: "mont-bold";
  color: var(--blue);
`;
const Label = styled.h4`
  font-family: "mont-bold";
  color: var(--blue);
  margin-bottom: 10px;
`;
const Addr = styled.p`
  font-size: 14px;
`;
const Number = styled.div`
  display: flex;
  align-items: center;
`;
const Icons = styled.div`
  width: 38px;
  margin-right: 10px;
`;
const Atag = styled.div`
  border: 1px solid #ff4844;
  padding: 6px 12px;
  border-radius: 8px;
  a {
    text-decoration: none;
    font-size: 16px;
    color: #000;
    font-family: "mont-medium";
  }
`;
const RightContent = styled.div`
  width: 60%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const RightHead = styled.h4`
  color: var(--blue);
  font-family: "mont-bold";
  margin-bottom: 10px;
  font-size: 20px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  border: 1px solid #3cccff;
  outline: none;
  height: 50px;
  border-radius: 20px;
  margin-bottom: 10px;
  padding: 0 30px;
  box-sizing: border-box;
`;
const TextArea = styled.textarea`
  border: 1px solid #3cccff;
  outline: none;
  height: 150px;
  border-radius: 20px;
  margin-bottom: 10px;
  padding: 20px 30px;
  box-sizing: border-box;
`;
const CoverSend = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
const SendIn = styled.div`
  background-color: #ff4844;
  color: #fff;
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 20px;
`;
const Send = styled.input`
  background-color: #ff4844;
  color: #fff;
  border: none;
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 20px;
`;
const Iframe = styled.div`
  width: 70%;
  margin: 0 auto;
  @media all and (max-width: 480px) {
    width: 85%;
  }
`;
